import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { FaWhatsapp } from "react-icons/fa6";
import Aboutus from "./Aboutus";
import Button from "./Button";
import PhotoCards from "./Card";
import Features from "./Featuers";
import FAQ from "./Faq";

const Hero = () => {
  useEffect(() => {
    ReactGA.initialize("G-6DVXFVY0XR");
    ReactGA.send("pageview");
  }, []);

  const handleClaimNowClick = () => {
    ReactGA.event({
      category: "CTA",
      action: "Claim Now Button Clicked",
      label: "Claim Bonus",
    });
    window.open("https://wa.link/ggnow", "_blank");
  };
  return (
    <div className="w-full h-screen">
      <div className="w-full h-full relative">
        <img
          onClick={handleClaimNowClick}
          src="/cricket (1).jpg"
          alt=""
          className="w-full h-full object-contain"
        />

        <div className="w-full bg-[#640E00] h-auto p-6 grid place-items-center">
          <h1 className="text-white text-3xl uppercase font-bold">
            #Indias first comparative fantasy sports game
          </h1>
        </div>
        <div>
          <Features  />
        </div>
        <div className="md:px-20 mt-20">
          <Aboutus  />
        </div>
        <div className="w-full h-auto  md:px-32 mt-20">
          <h1 className="text-center  text-3xl mb-10 uppercase font-semibold">
            It's easy to start playing on{" "}
            <span className="text-purple-600">Fantasy</span>
          </h1>
          <div>
            <PhotoCards onClick={handleClaimNowClick} />
          </div>
        </div>

        <div>
          <FAQ onClick={handleClaimNowClick} />
        </div>
        <div className="w-full h-[40vh]  bg-red-700 mt-20">
          <div className="grid place-items-center p-10">
            <h1 className="text-4xl text-white uppercase"> get in touch </h1>
            <p className="text-lg text-white">
              For any queries, emergencies, feedback, or complaints, we are here
              to help you 24/7 with our online services.
            </p>
            <Button handleClaimNowClick={handleClaimNowClick} />
          </div>
        </div>
        <div className="bg-gray-600 space-y-5 text-white ">
          <div className="grid place-items-center">
            <ul className="flex gap-3 text-red-300 font-semibold text-xl">
              <li onClick={handleClaimNowClick}>Privacy</li>
              <li onClick={handleClaimNowClick}>Terms of services</li>
              <li onClick={handleClaimNowClick}>Contact us</li>
              <li onClick={handleClaimNowClick}>Disclaimer</li>
            </ul>
          </div>
          <p className="text-center">
            Disclaimer: This website is only for 18+ users. If you are from
            Telangana, Orissa, Assam, Sikkim, or Nagaland, please leave the
            website immediately. Be aware of fraudsters; we only deal via
            WhatsApp. We only promote fantasy sports. No real money involvement.
          </p>
          <p className="text-center">
            © 2024 Gamexonline. All rights reserved.
          </p>
          <ul className="flex items-center justify-center gap-4">
            <li onClick={handleClaimNowClick}>facebook</li>
            <li onClick={handleClaimNowClick}>twitter</li>
            <li onClick={handleClaimNowClick}>instagram</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Hero;
