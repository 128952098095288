import React,{useEffect} from "react";
import { FaGift, FaMoneyBillWave, FaStar } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import ReactGA from "react-ga4";

const Features = () => {
  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);

  useEffect(() => {
    ReactGA.initialize("G-6DVXFVY0XR");
    ReactGA.send("pageview");
  }, []);

  const handleClaimNowClick = () => {
    ReactGA.event({
      category: "CTA",
      action: "Claim Now Button Clicked",
      label: "Claim Bonus",
    });
    window.open("https://wa.link/ggnow", "_blank");
  };

  return (
    <div
      id="features"
      className="w-full py-12 px-6 bg-gradient-to-r from-indigo-900 via-purple-800 to-indigo-600 text-white"
    >
      <div className="max-w-5xl mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8" data-aos="fade-up">
          Get Rewards 
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Reward Card 1 */}
          <div
            className="bg-white text-black p-4 rounded-md shadow-md hover:scale-105 transition-transform duration-300"
            data-aos="zoom-in"
          >
            <div className="flex justify-center items-center text-yellow-400 text-6xl mb-3">
              <FaGift />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-center">
              Welcome Bonos
            </h3>
            <p className="text-sm text-center mb-4">
              Get an instant bonos on your first deposit. Sign up and start
              playing to claim your rewards today!
            </p>
            <button
              onClick={handleClaimNowClick}
              className="bg-[#640E00] text-white text-sm px-4 py-2 uppercase rounded-md hover:bg-purple-800 w-full"
            >
              Claim b0nus
            </button>
          </div>
          {/* Reward Card 2 */}
          <div
            className="bg-white text-black p-4 rounded-md shadow-md hover:scale-105 transition-transform duration-300"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="flex justify-center items-center text-green-400 text-6xl mb-3">
              <FaMoneyBillWave />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-center">
              Benifits
            </h3>
            <p className="text-sm text-center mb-4">
              Bat without fear! Get Benifits on your losses and continue your
              journey toward big wins.
            </p>
            <button
              onClick={handleClaimNowClick}
              className="bg-[#640E00] text-white text-sm px-4 py-2 rounded-md hover:bg-purple-800 w-full"
            >
              Get Cashback
            </button>
          </div>
          {/* Reward Card 3 */}
          <div
            className="bg-white text-black p-4 rounded-md shadow-md hover:scale-105 transition-transform duration-300"
            data-aos="zoom-in"
            data-aos-delay="400"
          >
            <div className="flex justify-center items-center text-yellow-400 text-7xl mb-3">
              <FaStar />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-center">
              Loyalty Benifits
            </h3>
            <p className="text-sm text-center mb-4">
              The more you bat, the more you earn. Join our loyalty program and
              enjoy exclusive benefits.
            </p>
            <button
              onClick={handleClaimNowClick}
              className="bg-[#640E00] text-white text-sm px-4 py-2 rounded-md hover:bg-purple-800 w-full"
            >
              Join Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
