import React from "react";
import Button from "./Button";

const PhotoCards = () => {
  const cards = [
    {
      title: "High Security",
      description:
        "Security is our top priority and your data is safe at all times.",
      image:
        "https://staronlinebook.in/wp-content/uploads/2024/08/professional-baseball-players-2048x1280.jpg",
    },
    {
      title: "Best Sports ID Provider",
      description:
        "We provide the best fantasy sports IDs for players who want to play games with their skills..",
      image:
        "https://staronlinebook.in/wp-content/uploads/2024/08/baseball-players-professional-baseball-stadium-evening-game-2048x1280.jpg",
    },
    {
      title: "Working Since 2010",
      description:
        "We have over a decade of experience, offering reliable services..",
      image:
        "https://staronlinebook.in/wp-content/uploads/2024/08/professional-baseball-players-1-2048x1280.jpg",
    },
  ];

  return (
    <div className="grid  md:grid-cols-3 grid-cols-1 ">
      {cards.map((card, index) => (
        <div
          key={index}
          className="max-w-sm border-2  grid place-items-center bg-white shadow-lg rounded-lg "
        >
          <img
            className="w-full h-48 object-cover"
            src={card.image}
            alt={card.title}
          />
          <div className="p-6 grid place-items-center">
            <h2 className="text-2xl text-center font-bold text-gray-800 mb-2">
              {card.title}
            </h2>
            <p className="text-gray-600 text-center mb-4">{card.description}</p>
            <Button />
          </div>
        </div>
      ))}
    </div>
  );
};

export default PhotoCards;
