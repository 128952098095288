import React from "react";
import Button from "./Button";


const Aboutus = () => {
  return (
    <div className="about-us bg-white flex gap-3   ">
      <div className="border-[#FF9635] border  "></div>

      <div className="grid place-items-center">
        {" "}
        <h1 className="text-5xl  font-bold text-center font-mosntaratbold">
          About Gamex online Play
        </h1>
        <p className="w-full text-md text-center  mt-5">
          11X Online book is responsible Online ID promoter. We deals in all
          kinds of sports like online cricket id and several types of gaming
          sports IDs, Play games with our sports ids. We helped 60k+ players to
          find best online games. India’s No 1 Book.
        </p>
      <Button/>
      </div>
    </div>
  );
};

export default Aboutus;
