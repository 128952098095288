import React,{useEffect} from "react";
import { FaWhatsapp } from "react-icons/fa6";
import ReactGA from "react-ga4";
const Button = () => {
  useEffect(() => {
    ReactGA.initialize("G-6DVXFVY0XR");
    ReactGA.send("pageview");
  }, []);

  const handleClaimNowClick = () => {
    ReactGA.event({
      category: "CTA",
      action: "Claim Now Button Clicked",
      label: "Claim Bonus",
    });
    window.open("https://wa.link/ggnow", "_blank")
  };
  return (
    <button
      onClick={handleClaimNowClick}
      className="flex items-center  gap-3 text-sm uppercase text-white bg-[#640E00]  rounded-full px-12 mt-5 font-semibold py-3 "
    >
      <span>
        <FaWhatsapp />
      </span>
      grab your id now
    </button>
  );
};

export default Button;
