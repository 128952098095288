import React from "react";
import Hero from "./Hero";
import Aboutus from "./Aboutus";

const Homepage = () => {
  return (
    <div className="relative w-full h-auto">
      <Hero />
      {/* <div className="mt-[100vh]">
        <Aboutus />
      </div> */}
    </div>
  );
};

export default Homepage;
