import React from "react";
// import Poster from "./Components/Overly/Poster";
// import Poster from "./Components/Northernwarriors/Poster"
import Homepage from "./Components/Gamex/Homepage";
// import Poster from "./Components/run/Poster";
function App() {
  return (
    <div className="App">
      {/* <Poster /> */}
      <Homepage />
      {/* <Practice/>   */}
    </div>
  );
}

export default App;
